import { Helmet } from 'react-helmet-async';
import { useLocales } from 'src/locales';

const Head = ({ title = '' }: { title: string }) => {
  const { t } = useLocales();

  return (
    <Helmet>
      <title>{`${title} ${t('natives_dispatcher')}`}</title>
    </Helmet>
  );
};

export default Head;
