// material ui
import { Card, Table, TableBody, TableContainer, Stack } from '@mui/material';
// react
import { useCallback, useMemo } from 'react';
import Scrollbar from 'src/components/scrollbar';
// locales
import { useLocales } from 'src/locales';
// component
import { TableHead, TableNoData, getComparator, useTable } from 'src/components/table';
import { IOrderStats } from 'src/types';
import CustomTableRow from './custom-table-row';

export default function CustomerOrderedTable({ data }: { data: IOrderStats[] }) {
  const { t } = useLocales();

  const TABLE_COLUMNS = useMemo(
    () => [
      { field: 'orderId', title: t('order_code'), width: 100, align: 'center' as const },
      { field: 'deliveryDate', title: t('data_spedizione'), width: 200, align: 'center' as const },
      { field: 'recipient', title: t('destinatario'), width: 50, align: 'center' as const },
      { field: 'bottles', title: t('bottiglie'), width: 50, align: 'center' as const },
      { field: 'courier', title: t('corriere'), width: 50, align: 'center' as const },
    ],
    [t],
  );

  const table = useTable({
    defaultOrderBy: 'orderId',
    defaultOrder: 'desc',
  });

  const notFound = !data.length;

  const tableDataModifier = useCallback(
    (rows: IOrderStats[]) =>
      rows?.map((row) => ({
        ...row,
        deliveryDate:
          row?.deliveryDate !== null ? new Date(row?.deliveryDate).toLocaleDateString() : '----',
      })) || [],
    [],
  );

  const modifiedData = tableDataModifier(data);

  const dataFiltered = applyFilter({
    inputData: modifiedData,
    comparator:
      table.orderBy === 'orderId'
        ? getOrderIdComparator(table.order)
        : getComparator(table.order, table.orderBy),
  });

  return (
    <Stack spacing={1}>
      <Card sx={{ marginBottom: 2 }}>
        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <Scrollbar>
            <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
              <TableHead
                order={table.order}
                orderBy={table.orderBy}
                headLabel={TABLE_COLUMNS}
                onSort={table.onSort}
              />
              <TableBody>
                {dataFiltered.map((row) => (
                  <CustomTableRow key={row.orderId} row={row} headLabel={TABLE_COLUMNS} />
                ))}

                <TableNoData notFound={notFound} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
      </Card>
    </Stack>
  );
}
// apply filter
function applyFilter({
  inputData,
  comparator,
}: {
  inputData: IOrderStats[];
  comparator: (a: any, b: any) => number;
}) {
  inputData.sort((a, b) => {
    const sortOrder = comparator(a, b);
    return sortOrder;
  });
  return inputData;
}

function getOrderIdComparator(order: 'asc' | 'desc') {
  return (a: IOrderStats, b: IOrderStats) => {
    const [prefixA, yearA] = a.orderId.split('-');
    const [prefixB, yearB] = b.orderId.split('-');

    const numA = parseInt(prefixA.replace('NAT', ''), 10);
    const numB = parseInt(prefixB.replace('NAT', ''), 10);

    if (yearA !== yearB) {
      return order === 'asc' ? yearA.localeCompare(yearB) : yearB.localeCompare(yearA);
    }
    return order === 'asc' ? numA - numB : numB - numA;
  };
}
