import { m } from 'framer-motion';
// @mui
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// assets
import { ForbiddenIllustration } from 'src/assets/illustrations';
import { useLocales } from 'src/locales';
// components
import { RouterLink } from 'src/routes/components';
import { MotionContainer, varBounce } from 'src/components/animate';
import { PATH_CUSTOMER_AFTER_LOGIN, PATH_ADMIN_AFTER_LOGIN } from 'src/config-global';
import { paths } from 'src/routes/paths';
import { isLoggedIn, isRoleAdmin } from 'src/utils/check-user-is-admin';
// ----------------------------------------------------------------------

export default function View403() {
  const { t } = useLocales();
  let redirectPath = '';
  let redirectText = '';

  if (isLoggedIn()) {
    // User is logged in
    if (isRoleAdmin()) {
      // User is an admin
      redirectPath = PATH_ADMIN_AFTER_LOGIN;
      redirectText = t('client_orders');
    } else {
      // User is not an admin (assume customer)
      redirectPath = PATH_CUSTOMER_AFTER_LOGIN;
      redirectText = t('catalog');
    }
  } else {
    // User is not logged in
    redirectPath = paths.login;
    redirectText = t('login');
  }
  return (
    <MotionContainer
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <m.div variants={varBounce().in} style={{ textAlign: 'center' }}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          {t('no_permission')}
        </Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <Typography sx={{ color: 'text.secondary', textAlign: 'center' }}>
          {t('403_message_1')}
          <br />
          {t('403_message_2')}
        </Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
      </m.div>

      <Button component={RouterLink} href={redirectPath} size="large" variant="contained">
        {`${t('go_to')} ${redirectText}`}
      </Button>
    </MotionContainer>
  );
}
