import { useCallback, useEffect } from 'react';
// routes
// import { paths } from 'src/routes/paths';
import { useRouter, useSearchParams } from 'src/routes/hooks';
//
import { isRoleAdmin } from 'src/utils/check-user-is-admin';
import { PATH_CUSTOMER_AFTER_LOGIN, PATH_ADMIN_AFTER_LOGIN } from 'src/config-global';
import { useAuthContext } from '../hooks';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function GuestGuard({ children }: Props) {
  const router = useRouter();

  const searchParams = useSearchParams();

  // const returnTo = searchParams.get('returnTo') || paths.dashboard.root;
  const returnTo = searchParams.get('returnTo');

  const { authenticated } = useAuthContext();

  const check = useCallback(() => {
    if (authenticated) {
      const redirectPath = isRoleAdmin() ? PATH_ADMIN_AFTER_LOGIN : PATH_CUSTOMER_AFTER_LOGIN;
      if (returnTo || redirectPath) {
        router.replace(returnTo || redirectPath);
      }
    }
  }, [authenticated, returnTo, router]);

  useEffect(() => {
    check();
  }, [check]);

  return <>{children}</>;
}
