import { useEffect, useReducer, useCallback, useMemo } from 'react';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signOut,
  signInWithPopup,
  GoogleAuthProvider,
  GithubAuthProvider,
  TwitterAuthProvider,
  // sendEmailVerification,
  // sendPasswordResetEmail,
  signInWithEmailAndPassword,
  // createUserWithEmailAndPassword,
  OAuthProvider,
} from 'firebase/auth';

import axiosInstance, { endpoints } from 'src/utils/axios';
import { sendForgotPasswordLink } from 'src/api/auth';
// import { getFirestore, collection, doc, getDoc, setDoc } from 'firebase/firestore';
// config
import {
  AUTH_STORAGE_KEYS,
  localStorageGetItem,
  localStorageRemoveItem,
  localStorageSetItem,
} from 'src/utils/storage-available';
import { FIREBASE_API } from 'src/config-global';
//
import { AuthContext } from './auth-context';
import { ActionMapType, AuthStateType, AuthUserType } from '../../types';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);

// const DB = getFirestore(firebaseApp);

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  // LOGOUT
  const logout = useCallback(async () => {
    await signOut(AUTH);

    Object.keys(AUTH_STORAGE_KEYS).forEach((key) => {
      localStorageRemoveItem(key);
    });

    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  const initialize = useCallback(async () => {
    try {
      const accessToken = localStorageGetItem(AUTH_STORAGE_KEYS.accessToken);
      const refreshToken = localStorageGetItem(AUTH_STORAGE_KEYS.refreshToken);
      const userData = JSON.parse(localStorageGetItem(AUTH_STORAGE_KEYS.user) || '{}');

      if (accessToken) {
        // const res = await axiosInstance.post(`${HOST_API}${endpoints.auth.refreshToken}`, { refreshToken });
        // const { accessToken: aToken, refreshToken: rToken } = res.data;
        // const refreshData = await refreshAuthToken();
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: {
              ...userData,
              accessToken,
              refreshToken,
              // accessToken: aToken,
              // refreshToken: rToken,
            },
          },
        });
      } else {
        logout();
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      // console.error(error);
      // logout();
      const accessToken = localStorageGetItem(AUTH_STORAGE_KEYS.accessToken);
      const refreshToken = localStorageGetItem(AUTH_STORAGE_KEYS.refreshToken);

      const userData = JSON.parse(localStorageGetItem(AUTH_STORAGE_KEYS.user) || '{}');

      dispatch({
        type: Types.INITIAL,
        payload: {
          user: refreshToken
            ? {
                ...userData,
                accessToken,
                refreshToken,
              }
            : null,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const loginWithPassword = useCallback(async (email: string, password: string) => {
    const loginDetails = await signInWithEmailAndPassword(AUTH, email, password);
    if (loginDetails.user) {
      // eslint-disable-next-line
      let accessToken = await loginDetails?.user?.getIdToken();

      const token: { token: string } = { token: accessToken };
      const userDetails = await axiosInstance.post(
        endpoints.auth.firebase.loginWithPassword,
        token,
      );
      // refresh after claims are set
      accessToken = await loginDetails?.user?.getIdToken(true);
      const { refreshToken } = loginDetails.user;
      localStorageSetItem(AUTH_STORAGE_KEYS.accessToken, accessToken);
      localStorageSetItem(AUTH_STORAGE_KEYS.refreshToken, refreshToken);

      const { userData } = userDetails?.data?.data || {};

      localStorageSetItem(AUTH_STORAGE_KEYS.user, JSON.stringify(userData));

      dispatch({
        type: Types.LOGIN,
        payload: {
          user: {
            ...userData,
            accessToken,
            refreshToken,
          },
        },
      });
    }
  }, []);

  const loginWithGoogle = useCallback(async () => {
    const provider = new GoogleAuthProvider();

    const loginDetails = await signInWithPopup(AUTH, provider);

    if (loginDetails.user) {
      // eslint-disable-next-line
      let accessToken = await loginDetails?.user?.getIdToken();

      const token: { token: string } = { token: accessToken };
      const userDetails = await axiosInstance.post(
        endpoints.auth.firebase.loginWithPassword,
        token,
      );

      // refresh after claims are set
      accessToken = await loginDetails?.user?.getIdToken(true);
      const { refreshToken } = loginDetails.user;
      localStorageSetItem(AUTH_STORAGE_KEYS.accessToken, accessToken);
      localStorageSetItem(AUTH_STORAGE_KEYS.refreshToken, refreshToken);

      const { userData } = userDetails?.data?.data || {};

      localStorageSetItem(AUTH_STORAGE_KEYS.user, JSON.stringify(userData));

      dispatch({
        type: Types.LOGIN,
        payload: {
          user: {
            ...userData,
            accessToken,
            refreshToken,
          },
        },
      });
    }
  }, []);
  // apple login ---------------------------------------------------------------------
  const loginWithApple = useCallback(async () => {
    const appleProvider = new OAuthProvider('apple.com');
    appleProvider.addScope('email');
    appleProvider.addScope('name');

    const loginDetails = await signInWithPopup(AUTH, appleProvider);

    if (loginDetails.user) {
      let accessToken = await loginDetails?.user?.getIdToken();

      const token: { token: string } = { token: accessToken };
      const userDetails = await axiosInstance.post(
        endpoints.auth.firebase.loginWithPassword,
        token,
      );

      // refresh after claims are set
      accessToken = await loginDetails?.user?.getIdToken(true);
      const { refreshToken } = loginDetails.user;

      localStorageSetItem(AUTH_STORAGE_KEYS.accessToken, accessToken);
      localStorageSetItem(AUTH_STORAGE_KEYS.refreshToken, refreshToken);

      const { userData } = userDetails?.data?.data || {};

      localStorageSetItem(AUTH_STORAGE_KEYS.user, JSON.stringify(userData));

      dispatch({
        type: Types.LOGIN,
        payload: {
          user: {
            ...userData,
            accessToken,
            refreshToken,
          },
        },
      });
    }
    // const credential = await OAuthProvider.credentialFromResult(loginDetails);
    // const json = await OAuthProvider.credentialFromJSON(loginDetails);
  }, []);

  const loginWithGithub = useCallback(async () => {
    const provider = new GithubAuthProvider();

    await signInWithPopup(AUTH, provider);
  }, []);

  const loginWithTwitter = useCallback(async () => {
    const provider = new TwitterAuthProvider();

    await signInWithPopup(AUTH, provider);
  }, []);

  // REGISTER
  // const register = useCallback(
  //   async (email: string, password: string, firstName: string, lastName: string) => {
  //     const newUser = await createUserWithEmailAndPassword(AUTH, email, password);

  //     await sendEmailVerification(newUser.user);

  //     const userProfile = doc(collection(DB, 'users'), newUser.user?.uid);

  //     await setDoc(userProfile, {
  //       uid: newUser.user?.uid,
  //       email,
  //       displayName: `${firstName} ${lastName}`,
  //     });
  //   },
  //   [],
  // );

  // FORGOT PASSWORD
  const forgotPassword = useCallback(async (email: string) => {
    await sendForgotPasswordLink({ email });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'firebase',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      loginWithPassword,
      logout,
      // register,
      forgotPassword,
      loginWithGoogle,
      loginWithGithub,
      loginWithTwitter,
      loginWithApple,
    }),
    [
      status,
      state.user,
      //
      loginWithPassword,
      logout,
      // register,
      forgotPassword,
      loginWithGithub,
      loginWithGoogle,
      loginWithTwitter,
      loginWithApple,
    ],
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
