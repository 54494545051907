import { Box } from '@mui/system';
import { t } from 'i18next';
import { ICurrentMonthStats } from 'src/types';
import { fCurrencyFormat } from 'src/utils/format-number';
import MedianStatCard from './stats-card';

const titlesMap: { [key: string]: string } = {
  ordersReceived: 'ordini_ricevuti',
  ordersShipped: 'ordini_spediti',
  totalBottlesSold: 'bottiglie',
  totalRevenue: 'ricavi',
  totalMargin: 'margine',
  totalMarginPercentage: 'margine',
};

const iconsMap: { [key: string]: string } = {
  ordersReceived: 'mdi:package-variant-closed',
  ordersShipped: 'mdi:truck',
  totalBottlesSold: 'mdi:bottle-soda-classic-outline',
  totalRevenue: 'mdi:currency-eur',
  totalMargin: 'mdi:currency-eur',
  totalMarginPercentage: 'mdi:percent',
};

export default function CurrentMonthStatsSection({
  currentMonthData,
}: {
  currentMonthData: ICurrentMonthStats;
}) {
  const formattedData = {
    ordersReceived: currentMonthData.ordersReceived,
    ordersShipped: currentMonthData.ordersShipped,
    totalBottlesSold: currentMonthData.totalBottlesSold,
    totalRevenue: fCurrencyFormat(currentMonthData.totalRevenue?.toFixed(2)),
    totalMargin: fCurrencyFormat(currentMonthData.totalMargin?.toFixed(2)),
    totalMarginPercentage: fCurrencyFormat(currentMonthData.marginPercentage?.toFixed(2)),
  };

  const renderCurrentMonthStats = Object.entries(formattedData).map(([key, value]) => {
    const title = titlesMap[key] || key;
    const icon = iconsMap[key];

    return (
      <MedianStatCard
        key={key}
        value={value}
        subTitle={t(title === 'totalMarginPercentage' ? 'totalMargin' : title)}
        icon={icon}
      />
    );
  });

  return (
    <Box
      gap={2}
      display="grid"
      gridTemplateColumns={{
        xs: 'repeat(1, 1fr)',
        sm: 'repeat(2, 1fr)',
        md: 'repeat(3, 1fr)',
        lg: 'repeat(6, 1fr)',
      }}
      mt={4}
      mb={4}
    >
      {renderCurrentMonthStats}
    </Box>
  );
}
