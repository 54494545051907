// @mui
import Container from '@mui/material/Container';
import { useSearchParams } from 'react-router-dom';
// other libs
import v from 'voca';
// routes
import { paths } from 'src/routes/paths';
import { useLocales } from 'src/locales';
import { CLONE_QUERY } from 'src/constants/misc';
import { useGetOverlay } from 'src/api/admin';
import { LoadingScreen } from 'src/components/loading-screen';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import OverlayNewEditForm from '../overlay-new-edit-form';

// ----------------------------------------------------------------------

export default function OverlayCreateView() {
  const settings = useSettingsContext();
  const { t } = useLocales();

  const [searchParams] = useSearchParams();
  const clone_overlay_id = searchParams.get(CLONE_QUERY.clone_overlay_id);

  const { data: cloneOverlay, isLoading } = useGetOverlay({ overlayId: clone_overlay_id || '' });

  if (isLoading)
    return (
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <LoadingScreen />
      </Container>
    );

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={`${t('add')} ${v.lowerCase(t('a'))} ${v.lowerCase(t('new'))} ${v.lowerCase(
          t('overlay'),
        )}`}
        links={[
          { name: t('admin') },
          { name: t('overlays'), href: paths.admin.dashboard.overlays.root },
          { name: `${t('add')} ${t('overlay')}` },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <OverlayNewEditForm cloneOverlay={cloneOverlay} />
    </Container>
  );
}
