import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { t } from 'i18next';
import { ILogisticsStats } from 'src/types/misc';
import CustomTable from '../list/custom-table';

export default function LogisticsSection({
  ordersToBeShipped,
  ordersShippedToday,
  ordersShippedYesterday,
}: ILogisticsStats) {
  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {t('ordini_da_spedire')}
      </Typography>
      <CustomTable data={ordersToBeShipped} />

      <Typography variant="h5" sx={{ mb: 2, mt: 4 }}>
        {t('ordini_spediti_oggi')}
      </Typography>
      <CustomTable data={ordersShippedToday} />

      <Typography variant="h5" sx={{ mb: 2, mt: 4 }}>
        {t('ordini_spediti_ieri')}
      </Typography>
      <CustomTable data={ordersShippedYesterday} />
    </Box>
  );
}
