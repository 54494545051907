import * as Yup from 'yup';
import { useMemo, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import v from 'voca';
// import { useSWRConfig } from 'swr';
// @mui
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
// import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import { useLocales } from 'src/locales';
// components
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFSwitch, RHFTextField, RHFEditor } from 'src/components/hook-form';
// hooks
import { createOverlay, updateOverlay } from 'src/api/admin';
// types
import { IOverlayItem } from 'src/types';
// others
// import { endpoints } from 'src/utils/axios';
import { DatePicker } from '@mui/x-date-pickers';

// ----------------------------------------------------------------------

type Props = {
  currentOverlay?: IOverlayItem;
  cloneOverlay?: IOverlayItem;
};

export default function OverlayNewEditForm({ currentOverlay, cloneOverlay }: Props) {
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useLocales();

  const ProducerSchema = Yup.object().shape({
    content: Yup.string().required(`${t('content')} ${t('is_required')}`),
    startDate: Yup.date().required(`${t('startDate')} ${t('is_required')}`),
    endDate: Yup.date().required(`${t('endDate')} ${t('is_required')}`),
    active: Yup.boolean().required(`${t('active')} ${t('is_required')}`),
    isFallback: Yup.boolean().required(`${t('isFallback')} ${t('is_required')}`),
  });

  const defaultValues = useMemo(
    () => ({
      ...(currentOverlay || {}),
      content: currentOverlay?.content || cloneOverlay?.content || '',
      startDate: currentOverlay?.startDate || cloneOverlay?.startDate || null,
      endDate: currentOverlay?.endDate || cloneOverlay?.endDate || null,
      active: currentOverlay?.active || cloneOverlay?.active || false,
      isFallback: currentOverlay?.isFallback || cloneOverlay?.isFallback || false,
    }),
    [currentOverlay, cloneOverlay],
  );

  const methods = useForm({
    resolver: yupResolver<any>(ProducerSchema),
    defaultValues,
  });

  const {
    // watch,
    reset,
    control,
    handleSubmit,
    // setError,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (currentOverlay || cloneOverlay) {
      reset(defaultValues);
    }
  }, [currentOverlay, defaultValues, reset, cloneOverlay]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      let res = null;
      if (!currentOverlay) {
        res = await createOverlay(data);
        reset();
      } else {
        res = await updateOverlay(data);
      }
      enqueueSnackbar(currentOverlay ? `${t('update_success')}!` : `${t('create_success')}!`);
      if (res) {
        router.push(
          paths.admin.dashboard.overlays.details(data?.id ? data?.id : res?.data?.data?.id),
        );
      }
    } catch ({ message }) {
      if (message) {
        enqueueSnackbar(message, { variant: 'error' });
      }
    }
  });

  const renderDetails = (
    <Grid xs={12}>
      <Card>
        <Stack spacing={3} sx={{ p: 3 }}>
          {/* <Stack spacing={1.5}>
            <Typography variant="subtitle2">{t('content')}</Typography>
            <RHFEditor simple name="content" />
          </Stack> */}
          <Stack spacing={1.5}>
            <Typography variant="subtitle2">{t('content')}</Typography>
            <RHFEditor simple name="content" />
          </Stack>
          {/* DatePicker for Start Date */}
          <Stack spacing={1.5} gap={5} display="flex" flexDirection="row" alignItems="center">
            <Stack spacing={1.5}>
              {' '}
              <Typography variant="subtitle2">{t('valid_from')}</Typography>
              <Controller
                name="startDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    label="Start date"
                    value={field.value}
                    onChange={(date) => field.onChange(date)}
                    slotProps={{
                      textField: (params) => <RHFTextField {...params} name={field.name} />,
                    }}
                  />
                )}
              />
            </Stack>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('valid_through')}</Typography>
              <Controller
                name="endDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    label="End date"
                    value={field.value}
                    onChange={(date) => field.onChange(date)}
                    slotProps={{
                      textField: (params) => <RHFTextField {...params} name={field.name} />,
                    }}
                  />
                )}
              />
            </Stack>
          </Stack>

          {/* RHFSwitch for Active */}
          <RHFSwitch name="active" label={t('active')} />

          {/* RHFSwitch for isFallback */}
          <RHFSwitch name="isFallback" disabled label={t('fallback')} />
        </Stack>
      </Card>
    </Grid>
  );

  const renderActions = (
    <Grid xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
      <LoadingButton
        type="submit"
        variant="contained"
        size="large"
        loading={isSubmitting}
        sx={{ ml: 2 }}
      >
        {!currentOverlay ? `${t('add')} ${v.lowerCase(t('overlay'))}` : t('save_changes')}
      </LoadingButton>
    </Grid>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        {renderDetails}
        {renderActions}
      </Grid>
    </FormProvider>
  );
}
