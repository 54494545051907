// lib
import { useState, useEffect, useCallback, useMemo, Children } from 'react';
// mui
import { Box, Card, ListItemText, Container, Typography } from '@mui/material';
// components
import { LoadingScreen } from 'src/components/loading-screen';
// types
import { IDetailsTableKeys, IOverlayItem } from 'src/types';
import { t } from 'i18next';

export default function OverlayContentView({
  currentOverlay,
  isLoading,
}: {
  currentOverlay: IOverlayItem;
  isLoading: boolean;
}) {
  const [modOverlay, setModOverlay] = useState<IOverlayItem | undefined>(undefined);

  const productDetailsKeys: { [section: string]: IDetailsTableKeys[] } = useMemo(
    () => ({
      overlayInfo: [
        { key: 'startDate', label: 'startDate' },
        { key: 'endDate', label: 'endDate' },
        { key: 'createdAt', label: 'createdAt' },
        { key: 'updatedAt', label: 'updatedAt' },
      ],
      status: [
        {
          key: 'active',
          label: t('active'),
        },
        {
          key: 'isFallback',
          label: t('fallback'),
        },
      ],
    }),
    [],
  );

  function formatDateToLocaleString(dateString: string | number | Date) {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  }

  const modifyProductHandler = useCallback((overlay: IOverlayItem) => {
    const currOverlay: IOverlayItem = {
      ...overlay,
      startDate: overlay.startDate ? formatDateToLocaleString(overlay.startDate) : undefined,
      endDate: overlay.endDate ? formatDateToLocaleString(overlay.endDate) : undefined,
      createdAt: overlay.createdAt ? formatDateToLocaleString(overlay.createdAt) : undefined,
      updatedAt: overlay.updatedAt ? formatDateToLocaleString(overlay.updatedAt) : undefined,
    };
    setModOverlay(currOverlay);
  }, []);

  useEffect(() => {
    if (currentOverlay) {
      modifyProductHandler(currentOverlay);
    }
  }, [currentOverlay, modifyProductHandler]);

  if (isLoading)
    return (
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <LoadingScreen />
      </Container>
    );

  return (
    <Card>
      <Box display="flex" flexWrap="wrap" margin={2}>
        {modOverlay && (
          <>
            {Children.toArray(
              Object.entries(productDetailsKeys).map(
                ([sectionTitle, keys]) =>
                  sectionTitle !== 'content' && (
                    <Box width="100%" mb={2} key={sectionTitle}>
                      <Typography variant="h6" gutterBottom>
                        {t(sectionTitle)}
                      </Typography>
                      <Box display="flex" flexWrap="wrap">
                        {Children.toArray(
                          keys.map((item) => (
                            <Box
                              padding={1}
                              width={{ xs: '100%', sm: item?.fullWidth ? '100%' : '50%' }}
                            >
                              <ListItemText
                                primary={t(item.label)}
                                secondary={(() => {
                                  if (item.key === 'active' || item.key === 'isFallback') {
                                    return modOverlay[item.key as keyof IOverlayItem]
                                      ? t('yes')
                                      : t('no');
                                  }
                                  if (
                                    typeof modOverlay[item.key as keyof IOverlayItem] === 'boolean'
                                  ) {
                                    return modOverlay[item.key as keyof IOverlayItem]
                                      ? t('yes')
                                      : t('no');
                                  }
                                  const value = modOverlay[item.key as keyof IOverlayItem];
                                  return value instanceof Date
                                    ? value.toISOString()
                                    : value || '----';
                                })()}
                                primaryTypographyProps={{
                                  typography: 'body2',
                                  color: 'text.secondary',
                                  mb: 0.5,
                                }}
                                secondaryTypographyProps={{
                                  typography: 'subtitle2',
                                  color: 'text.primary',
                                  component: 'span',
                                }}
                              />
                            </Box>
                          )),
                        )}
                      </Box>
                    </Box>
                  ),
              ),
            )}
            {modOverlay.content && (
              <Box width="100%" mb={2}>
                <Typography variant="h6" gutterBottom>
                  {t('content')}
                </Typography>
                <Box
                  padding={1}
                  sx={{
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    padding: 2,
                    maxHeight: '300px',
                    overflowY: 'auto',
                  }}
                >
                  <Typography
                    component="div"
                    dangerouslySetInnerHTML={{ __html: modOverlay.content || '' }}
                  />
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>
    </Card>
  );
}
