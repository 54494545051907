// routes
import { useParams } from 'src/routes/hooks';
import OverlayEditView from 'src/sections/admin/overlays/view/overlay-edit-view';
// sections

// ----------------------------------------------------------------------

export default function OverlayEditPage() {
  const params = useParams();

  const { id } = params;

  return <OverlayEditView overlayId={`${id}`} />;
}
