import { ICartProduct, IProductItem } from 'src/types';
import { WINE_TYPE_COFFEE } from './xp-utils';

const STEPPER_COFFEE_VALUE = 1;
const STEPPER_THRESHOLD_VALUE = 3;
const STEPPER_DEFAULT_VALUE = 6;

export const quantityStepper = ({
  wine_type,
  price,
  multiplesThreshold,
}: {
  wine_type: string;
  price: number;
  multiplesThreshold: number;
}) => {
  if (wine_type === WINE_TYPE_COFFEE) {
    return STEPPER_COFFEE_VALUE;
  }
  return price > multiplesThreshold ? STEPPER_THRESHOLD_VALUE : STEPPER_DEFAULT_VALUE;
};

export const maxOrderable = ({
  totalQuantity,
  product,
}: {
  totalQuantity: number;
  product: IProductItem;
}) => {
  if (
    totalQuantity === 0 ||
    product.per_client_max === 0 ||
    totalQuantity < product.per_client_max
  ) {
    return totalQuantity;
  }
  return product.per_client_max;
};

export const handleQuantity = ({
  maxQty,
  newQuantity,
  step,
}: {
  maxQty: number;
  newQuantity: number;
  step: number;
}) => {
  if (newQuantity >= maxQty) {
    return maxQty;
  }
  if (newQuantity === maxQty - step) {
    return Math.floor((maxQty - 1) / step) * step;
  }
  if (newQuantity % step === 0) {
    return newQuantity;
  }

  return Math.floor(newQuantity / step) * step;
};

export const calcDiscOfItem = (cartProduct: ICartProduct) =>
  (((cartProduct?.base_price || 0) - (cartProduct?.end_price || 0)) /
    (cartProduct?.base_price || 0)) *
  100;

export const calcTotalPriceOfItem = ({
  cartProduct,
  discount,
}: {
  cartProduct: ICartProduct;
  discount: number;
}) => (cartProduct?.end_price || 0) * (cartProduct?.quantity || 0) + discount;

export const formatDiscValue = (discPct = 0, discAmt = 0) =>
  discPct >= 0 ? Math.abs(discAmt) : -Math.abs(discAmt);
