import { forwardRef, useMemo } from 'react';
// @mui
// import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';
import { Typography } from '@mui/material';
// routes
import { RouterLink } from 'src/routes/components';
import NativesLogo from 'src/assets/images/logo/natives_full_logo.svg';
import { isLoggedIn, isRoleAdmin } from 'src/utils/check-user-is-admin';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  showLogoText?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, showLogoText = false, sx }, ref) => {
    // , ...other
    // const theme = useTheme();

    // const PRIMARY_LIGHT = theme.palette.primary.light;

    // const PRIMARY_MAIN = theme.palette.primary.main;

    // const PRIMARY_DARK = theme.palette.primary.dark;

    // OR using local (public folder)
    // -------------------------------------------------------
    // const logo = (
    //   <Box
    //     component="img"
    //     src="/logo/logo_single.svg" => your path
    //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    //   />
    // );

    const checkLoggedIn = isLoggedIn();
    const checkRoleAdmin = isRoleAdmin();

    const Routes = useMemo(() => {
      if (!checkLoggedIn) {
        return `${paths.login}`;
      }
      if (checkRoleAdmin) {
        return `${paths.admin.clients.root}`;
      }
      return `${paths.customer.catalog}`;
    }, [checkLoggedIn, checkRoleAdmin]);

    const logo = (
      <Box
        ref={ref}
        component="img"
        alt="Natives Dispatcher"
        src={NativesLogo}
        sx={{ width: 40, height: 40, cursor: 'pointer', objectFit: 'contain', ...sx }}
      />
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link
        component={RouterLink}
        href={Routes}
        sx={{
          display: 'flex',
          width: 'fit-content',
          alignItems: 'center',
          gap: 1.5,
          textDecoration: 'none !important',
        }}
      >
        {logo}
        {showLogoText && (
          <Typography component="span" variant="h6" pt={1}>
            Dispatcher
          </Typography>
        )}
      </Link>
    );
  },
);

export default Logo;
