import { LazyMotion, m, domMax } from 'framer-motion';

import Banner from 'src/components/banner';
import { ENVIRONMENT } from 'src/config-global';
import { ENVIRONMENTS } from 'src/constants/misc';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export function MotionLazy({ children }: Props) {
  return (
    <LazyMotion strict features={domMax}>
      <Banner />
      <m.div
        style={{ height: '100%', marginTop: ENVIRONMENT === ENVIRONMENTS.prod ? 0 : '1.5rem' }}
      >
        {children}
      </m.div>
    </LazyMotion>
  );
}
