import { m } from 'framer-motion';
import { Children } from 'react';
// @mui
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Button, Divider, Box, Stack } from '@mui/material';
// utils
import { fCurrency } from 'src/utils/format-number';
import { useLocation } from 'react-router-dom';
import { useLocales } from 'src/locales';
import { useCheckoutContext } from 'src/sections/customer/checkout/context';
import { useRouter } from 'src/routes/hooks';
// components
import Iconify from 'src/components/iconify';
import EmptyContent from 'src/components/empty-content';
import Scrollbar from 'src/components/scrollbar';
import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { paths } from 'src/routes/paths';
import CartProductItem from './cart-product-item';
// ----------------------------------------------------------------------

export default function CartPopover() {
  const { t } = useLocales();
  const popover = usePopover();
  const router = useRouter();
  const { pathname } = useLocation();

  const checkout = useCheckoutContext();

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={popover.open ? 'inherit' : 'default'}
        onClick={popover.onOpen}
        disabled={pathname === paths.customer.checkout}
        sx={{
          ...(popover.open && {
            bgcolor: (theme) => theme.palette.action.selected,
          }),
        }}
      >
        <Badge badgeContent={checkout.totalItems} color="error" max={99}>
          <Iconify icon="solar:cart-3-bold" width={24} color="text.primary" />
        </Badge>
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 320 }}>
        <Typography variant="h6" sx={{ p: 1.5 }}>
          {t('my_cart')}{' '}
          <Typography component="span">
            ({checkout.totalItems} {t('items')})
          </Typography>
        </Typography>
        <Divider />

        <Scrollbar sx={{ height: 320 }}>
          {!checkout.totalItems ? (
            <EmptyContent
              title={`${t('cart_is_empty')}!`}
              description={`${t('look_like_you_have_no_items_in_your_shopping_cart')}.`}
              imgUrl="/assets/icons/empty/ic_cart.svg"
              sx={{ pt: 5, pb: 10 }}
            />
          ) : (
            Children.toArray(
              checkout.cartProducts.map((cartProduct) => (
                <CartProductItem cartProduct={cartProduct} />
              )),
            )
          )}
        </Scrollbar>
        <Divider />
        <Box sx={{ p: 1.5 }}>
          {checkout.totalItems > 0 && (
            <Stack direction="row" justifyContent="space-between" mb={1}>
              <Typography variant="subtitle1">{t('total')}</Typography>
              <Box sx={{ textAlign: 'right' }}>
                <Typography variant="subtitle1" sx={{ color: 'error.main' }}>
                  {fCurrency(checkout.total || 0)}
                </Typography>
                <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
                  ({t('vat_excluded_if_applicable')})
                </Typography>
              </Box>
            </Stack>
          )}
          <Button
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            disabled={!checkout.totalItems}
            onClick={() => {
              popover.onClose();
              router.push(paths.customer.checkout);
            }}
          >
            {t('checkout')}
          </Button>
        </Box>
      </CustomPopover>
    </>
  );
}
