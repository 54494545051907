export const CHECKOUT_TABS = {
  ORDER: 0,
  ADDRESS_AND_DELIVERY: 1,
  ORDER_SUMMARY: 2,
  // CONFIRM_ORDER: 3,
};

export const PRODUCT_CHECKOUT_STEPS = [
  'order',
  'address&delivery',
  'order_summary',
  // 'confirm_order',
];
