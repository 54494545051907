export const FILE_TYPES = {
  XLSX: 'xlsx',
  CSV: 'csv',
  PDF: 'pdf',
  XLSX_LOGISTICS: 'logistic-xlsx',
};

export const ENVIRONMENTS = {
  dev: 'develop',
  stage: 'stage',
  prod: 'production',
};

export const TABLE_DEFAULTS = {
  CURRENT_PAGE: 0,
  ROWS_PER_PAGE: 10,
  TOTAL_PAGES: 1,
  TOTAL_ROWS: 0,
};

export const FILE_UPLOAD_PATHS = {
  products: 'products',
  producer: 'producer',
  wineType: 'wineType',
};

export const MIME_TYPES = {
  jpg: 'image/jpg',
};

export const CLONE_QUERY = {
  clone_order_code: 'clone_order_code',
  clone_product_sku: 'clone_product_sku',
  clone_producer_slug: 'clone_producer_slug',
  clone_client: 'clone_client',
  clone_user: 'clone_user',
  clone_wine_type: 'clone_wine_type',
  clone_tasting: 'clone_tasting',
  clone_overlay_id: 'clone_overlay_id',
};

export const STAT_ROWS_PER_PAGE_OPTIONS = [25, 50, 75, 100];
export const DEFAULT_STAT_ROWS_PER_PAGE = 25;

export const INVOICE_BTN_COLOR = '#00A76F';
export const INVOICE_BTN_HOVER_COLOR = '#15bc84';

export const darkGreen = '#388E3C';
export const darkRed = '#D32F2F';

export const generateYears = (currentYear: number) =>
  Array(currentYear - 2017 + 1)
    .fill({ value: 2017 })
    .map((_, index) => ({ value: 2017 + index, label: 2017 + index }));

export const months = [
  { label: 'jan', value: 1 },
  { label: 'feb', value: 2 },
  { label: 'mar', value: 3 },
  { label: 'apr', value: 4 },
  { label: 'may', value: 5 },
  { label: 'jun', value: 6 },
  { label: 'jul', value: 7 },
  { label: 'aug', value: 8 },
  { label: 'sep', value: 9 },
  { label: 'oct', value: 10 },
  { label: 'nov', value: 11 },
  { label: 'dec', value: 12 },
];
