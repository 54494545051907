// @mui
import { Box, Card, Link, Typography, useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
// theme
import { bgBlur } from 'src/theme/css';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Logo from 'src/components/logo';
import { useSettingsContext } from 'src/components/settings';
import { RouterLink } from 'src/routes/components';
import { t } from 'i18next';
import { paths } from 'src/routes/paths';
import { FOOTER } from '../config-layout';

type FooterProps = {
  isAuthPage?: boolean;
};

export default function Footer({ isAuthPage = false }: FooterProps) {
  const theme = useTheme();
  const settings = useSettingsContext();
  const isNavHorizontal = settings.themeLayout === 'horizontal';
  const isNavMini = settings.themeLayout === 'mini';
  const lgUp = useResponsive('up', 'lg');

  const NAV_LINKS = [
    { name: t('catalog'), href: paths.customer.catalog },
    { name: t('producers'), href: paths.customer.producers },
    { name: t('order_list'), href: paths.customer.orders },
  ];

  return (
    <Card
      component="footer"
      sx={{
        alignSelf: 'center',
        // alignSelf: isAuthPage ? 'center' : { xs: 'center', lg: 'flex-end' },
        height: { xs: 'fit-content', md: 'fit-content' },
        width: '100%',
        justifyContent: 'center',
        textAlign: { xs: 'center', md: 'left' },
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        gap: { xs: 2, md: 8, lg: 10, xl: 15 },
        py: { xs: 3, md: 5 },
        px: { xs: 2, md: 4 },
        // marginTop: isAuthPage ? 0 : 3,
        marginX: isAuthPage ? 0 : 3,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        boxShadow: isAuthPage ? 'none' : theme.shadows[3],
        borderRadius: isAuthPage ? 0 : 2,
        ...(lgUp && {
          // width: `calc(100% - ${NAV.W_VERTICAL + 40}px)`,
          height: FOOTER.F_DESKTOP,
          ...(isNavHorizontal && {
            // width: 1,
            bgcolor: 'background.default',
            height: FOOTER.F_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini &&
            {
              // width: `calc(100% - ${NAV.W_MINI + 1}px)`,
            }),
        }),
      }}
    >
      <Stack direction={{ xs: 'column' }} alignItems="center">
        <Logo
          sx={{
            width: { xs: 120, sm: 180, md: 240 },
            height: { xs: 50, md: 70 },
          }}
        />
      </Stack>
      <Stack direction={{ xs: 'column' }}>
        {NAV_LINKS.map((list) => (
          <Link
            key={list.name}
            component={RouterLink}
            href={typeof list.href === 'string' ? list.href : list.href.root}
            color="inherit"
            variant="body2"
            sx={{ display: 'block', mb: 1 }}
          >
            {list.name}
          </Link>
        ))}
      </Stack>
      <Stack direction={{ xs: 'column' }}>
        <Typography variant="body2">Via Tortona 33, Milano</Typography>
      </Stack>
      <Stack direction={{ xs: 'column' }} spacing={1}>
        <Typography variant="body2">Xpitality Srl, P. IVA 08739280967</Typography>
        <Box sx={{ cursor: 'pointer' }} id="csconsentlink">
          <Typography variant="body2">{t('cookie_settings')}</Typography>
        </Box>
      </Stack>
    </Card>
  );
}
