import * as React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import TextField, { TextFieldProps } from '@mui/material/TextField';
// other libs
import { NumericFormat, NumericFormatProps } from 'react-number-format';
// utils
import { localStorageGetItem } from 'src/utils/storage-available';
//
import { allLangs, defaultLang } from 'src/locales/config-lang';
// ----------------------------------------------------------------------
interface CustomProps {
  // eslint-disable-next-line no-unused-vars
  name: string;
  onChange?: (event: { target: { name: string; value: string } }) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  thousandSeparator?: string | boolean | undefined;
  decimalScale?: number | undefined;
  allowNegative?: boolean;
  value?: number;
}

type Props = TextFieldProps & CustomProps;

type Values = { value: string };

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>((props, ref) => {
  const {
    onChange,
    onFocus,
    onBlur,
    thousandSeparator = false,
    decimalScale = 2,
    allowNegative = false,
    value,
    ...other
  } = props;

  const [formattedValue, setFormattedValue] = React.useState(value || '0');

  React.useEffect(() => {
    if (String(value) !== String(formattedValue)) {
      if (value !== undefined) setFormattedValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleFocus = () => {
    // Temporarily hide leading '0' when the input field is focused
    const inputString = String(formattedValue);
    if (inputString && inputString.startsWith('0')) {
      setFormattedValue(inputString.slice(1));
    }

    if (onFocus) {
      onFocus();
    }
  };

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={
        onChange
          ? (values: Values) => {
              setFormattedValue(values.value);
              if (onChange) {
                onChange({
                  target: {
                    name: props.name,
                    value: values.value,
                  },
                });
              }
            }
          : undefined
      }
      thousandSeparator={thousandSeparator}
      decimalScale={decimalScale}
      allowNegative={allowNegative}
      onFocus={handleFocus}
      value={formattedValue}
      onBlur={() => {
        if (!formattedValue) {
          setFormattedValue('0');
        }
        if (onBlur) {
          onBlur();
        }
      }}
    />
  );
});

export default function RHFDecimalField({
  name,
  // helperText,
  // type,
  thousandSeparator = false,
  decimalScale = 2,
  allowNegative = false,
  ...other
}: Props) {
  const { control } = useFormContext();

  const langStorage = localStorageGetItem('i18nextLng');

  const currentLang = allLangs.find((lang) => lang.value === langStorage) || defaultLang;

  const isCurrentLangEn = currentLang.value === 'en';
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          InputProps={{
            inputComponent: NumericFormatCustom as any,
          }}
          inputProps={{
            thousandSeparator: isCurrentLangEn ? ',' : '.',
            decimalSeparator: isCurrentLangEn ? '.' : ',',
            decimalScale,
            allowNegative,
          }}
          error={!!error}
          helperText={error ? error?.message : ''}
          {...other}
        />
      )}
    />
  );
}
