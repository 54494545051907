import { useLocales } from 'src/locales';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import v from 'voca';
// mui
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tab,
  Tabs,
  Tooltip,
  IconButton,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useBoolean } from 'src/hooks/use-boolean';
// import { useSWRConfig } from 'swr';
// component
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import Iconify from 'src/components/iconify';
// import Head from 'src/components/head';
//
// import { endpoints } from 'src/utils/axios';
// routes
import { CLONE_QUERY } from 'src/constants/misc';
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
import { deleteOverlay, useGetOverlay } from 'src/api/admin';
import OverlayContentView from './overlay-content-view';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const OVERLAY_DETAILS_TAB = {
  details: '0',
};

export default function OverlayDetailsView({ overlayId }: { overlayId: string }) {
  const { t } = useLocales();
  const navigate = useNavigate();
  const query = useQuery();
  const settings = useSettingsContext();
  const [currentTab, setCurrentTab] = useState('0');

  const deleteDialog = useBoolean();

  const { enqueueSnackbar } = useSnackbar();

  const { data: overlay, isLoading } = useGetOverlay({ overlayId });

  useEffect(() => {
    setCurrentTab(query.get('activeTab') || '0');
  }, [query]);

  const handleTabChange = (event: any, newValue: string) => {
    if (newValue === OVERLAY_DETAILS_TAB.details) {
      navigate('.', { replace: true });
    } else {
      navigate(`?activeTab=${newValue}`);
    }
    setCurrentTab(newValue);
  };

  const handleDeleteClient = async () => {
    try {
      deleteDialog.onFalse();
      await deleteOverlay(overlay?.id || '');
      navigate(paths.admin.dashboard.overlays.root);
      enqueueSnackbar(`${t('deleted_overlay_successfully')}!`);
    } catch (error) {
      if (error?.response?.data) {
        enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      }
    }
  };

  const handleCloneClick = () => {
    navigate(`${paths.admin.dashboard.overlays.root}?${CLONE_QUERY.clone_client}=${overlayId}`);
  };

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
          // heading={`${
          //   overlay?.content && overlay?.content?.length > 50
          //     ? `${overlay.content.slice(0, 50)}...`
          //     : overlay?.content ?? ''
          // }`}
          links={[
            ...[
              { name: t('admin') },
              { name: t('overlays'), href: paths.admin.dashboard.overlays.root },
            ],
            // ...(overlay?.content
            //   ? [
            //       {
            //         name:
            //           overlay.content.length > 50
            //             ? `${overlay.content.slice(0, 50)}...`
            //             : overlay.content ?? '',
            //       },
            //     ]
            //   : []),
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />
        <Box
          display="flex"
          flexDirection={{ xs: 'column-reverse', sm: 'row' }}
          justifyContent={{ xs: 'center', sm: 'space-between' }}
          alignItems={{ xs: 'flex-end', sm: 'start' }}
          gap={1}
        >
          <Box width="100%" mb={1}>
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              sx={{
                gap: 0.2,
                '& .MuiTabScrollButton-root': {
                  width: '1.5rem',
                },
              }}
            >
              <Tab label={t('details')} value={OVERLAY_DETAILS_TAB.details} />
            </Tabs>
          </Box>
          <Box display="flex" alignItems="flex-end" gap={2} aria-label="overlay-edit-delete">
            <Tooltip title={`${t('edit')} ${t('client')}`} placement="top" arrow>
              <IconButton
                component={RouterLink}
                href={paths.admin.dashboard.overlays.edit(overlayId)}
              >
                <Iconify icon="solar:pen-bold" />
              </IconButton>
            </Tooltip>

            <Tooltip title={t('clone')}>
              <IconButton onClick={handleCloneClick}>
                <Iconify icon="fa6-solid:clone" width={20} color="error.light" />
              </IconButton>
            </Tooltip>

            <Tooltip title={`${t('delete')} ${t('client')}`} placement="top" arrow>
              <IconButton sx={{ color: 'error.main' }} onClick={() => deleteDialog.onTrue()}>
                <Iconify icon="solar:trash-bin-trash-bold" />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        {currentTab === OVERLAY_DETAILS_TAB.details && overlay && (
          <OverlayContentView currentOverlay={overlay} isLoading={isLoading} />
        )}
      </Container>
      <Dialog open={deleteDialog.value} onClose={() => deleteDialog.onFalse()}>
        <DialogTitle>{t('delete_confirmation')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{`${t('are_you_sure_you_want_to_delete')} ${v.lowerCase(
            t('overlay'),
          )}?`}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => deleteDialog.onFalse()}>{t('cancel')}</Button>
          <Button onClick={handleDeleteClient} color="error" autoFocus>
            {t('delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
