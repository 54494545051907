// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
import { useLocales } from 'src/locales';
// hooks
import { useGetOverlay } from 'src/api/admin';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
// types
import { IFetchBaseParams } from 'src/types';
//
import { LoadingScreen } from 'src/components/loading-screen';
import OverlayNewEditForm from '../overlay-new-edit-form';

// ----------------------------------------------------------------------

export default function OverlayEditView({ overlayId }: IFetchBaseParams) {
  const settings = useSettingsContext();
  const { data: overlay, isLoading } = useGetOverlay({ overlayId });

  const { t } = useLocales();

  if (isLoading)
    return (
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <LoadingScreen />
      </Container>
    );

  // Safely parse the startDate and endDate
  const parsedOverlay = overlay
    ? {
        ...overlay,
        startDate: overlay.startDate ? new Date(overlay.startDate) : undefined,
        endDate: overlay.endDate ? new Date(overlay.endDate) : undefined,
      }
    : undefined;

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={`${t('edit')}`}
        // heading={`${t('edit')} ${parsedOverlay?.content || ''}`}
        links={[
          { name: t('admin') },
          { name: t('overlays'), href: paths.admin.dashboard.overlays.root },
          { name: `${t('edit')}` },
          // { name: `${t('edit')} ${parsedOverlay?.content || ''}` },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <OverlayNewEditForm currentOverlay={parsedOverlay} />
    </Container>
  );
}
