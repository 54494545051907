import { Box } from '@mui/system';
import { IOrderStats } from 'src/types';
import CustomTable from '../list/custom-table';

interface IAdministrativeStats {
  administrationData: IOrderStats[];
}
export default function AdministrationSection({ administrationData }: IAdministrativeStats) {
  return (
    <Box>
      <CustomTable data={administrationData} />
    </Box>
  );
}
