import { Suspense, useEffect } from 'react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  BrowserRouter,
} from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/react';
//
import App from './App';
import { ENVIRONMENT, GTM_ID, SENTRY_CONFIG } from './config-global';
import { CONSTANT_VARIABLES } from './constants/constants-variables';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const tagManagerArgs = {
  gtmId: GTM_ID,
};

const sentryArgs = {
  dsn: SENTRY_CONFIG.dsn,
  integrations: [
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Sentry.Replay({
      maskAllInputs: false,
      blockAllMedia: false,
    }),
  ],
  env: SENTRY_CONFIG.env,
  enabled: ENVIRONMENT === CONSTANT_VARIABLES.PRODUCTION,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
};

TagManager.initialize(tagManagerArgs);
if ([CONSTANT_VARIABLES.PRODUCTION, CONSTANT_VARIABLES.STAGE].includes(ENVIRONMENT)) {
  Sentry.init(sentryArgs);
}

const CustomApp = () => {
  const location = useLocation();

  useEffect(() => {
    const dataLayerArgs = {
      dataLayer: {
        event: 'virtualPageview',
        pagePath: document.location.pathname,
        pageTitle: document?.title,
        pageUrl: `${document.location.protocol}//${document.location.hostname}${document.location.pathname}${document.location.search}`,
      },
    };

    TagManager.dataLayer(dataLayerArgs);
  }, [location]);

  return <App />;
};

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <CustomApp />
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>,
);
