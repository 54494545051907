import { useMemo } from 'react';
import useSWR from 'swr';
// utils
import { fetcher, fetcherLegacy } from 'src/utils/axios';
// import SnackbarUtils from 'src/utils/snackbar-utils-configurator';

interface IFetchResult<ResultType> {
  data?: ResultType;
  isLoading: boolean;
  error: any;
  isValidating: boolean;
}

const useGetData = <ParamType, ResultType>({
  endpoint,
  params,
  isLegacy = false,
}: {
  endpoint: string | null;
  params?: ParamType;
  isLegacy?: boolean;
}): IFetchResult<ResultType> => {
  const fetcherFn = isLegacy ? fetcherLegacy : fetcher;
  let URL;

  if (!endpoint) {
    URL = null;
  } else if (params) {
    URL = [endpoint, { params }];
  } else {
    URL = [endpoint];
  }
  const { data, error, isLoading, isValidating } = useSWR<ResultType>(URL, fetcherFn);

  // if (error) {
  //   SnackbarUtils.error(`${error?.response?.data?.message || JSON.stringify(error || '{}')}`);
  // }

  const memoizedValue = useMemo(
    () => ({
      data,
      error,
      isLoading,
      isValidating,
    }),
    [data, error, isLoading, isValidating],
  );
  return memoizedValue;
};

export default useGetData;
