import { Avatar, Box, Card, Typography } from '@mui/material';
import Iconify from 'src/components/iconify';

export default function StatsCard({
  value,
  subTitle,
  icon,
}: {
  value: number | string;
  subTitle: string;
  icon: string;
}) {
  return (
    <Card sx={{ padding: '2rem 1rem' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <Avatar
          sx={{
            backgroundColor: 'success.main',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          aria-label="icon"
        >
          <Iconify icon={icon} width="24" />
        </Avatar>

        <Typography variant="h3">{value || 0}</Typography>

        <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
          {subTitle}
        </Typography>
      </Box>
    </Card>
  );
}
