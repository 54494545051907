// routes
import { useParams } from 'src/routes/hooks';
// sections
import OverlayDetailsView from 'src/sections/admin/overlays/view/overlay-details-view';

// ----------------------------------------------------------------------

export default function OverlayDetailsPage() {
  const params = useParams();

  const { id } = params;
  return <OverlayDetailsView overlayId={`${id}`} />;
}
