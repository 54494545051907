import axiosInstance, { endpoints } from 'src/utils/axios';
import { IForgotPasswordPayload, IResetPasswordPayload } from 'src/types';

export async function sendForgotPasswordLink(data: IForgotPasswordPayload) {
  const URL = `${endpoints.auth.passwords}`;
  const res = await axiosInstance.post(URL, data);
  return res;
}

export async function resetPassword({
  token,
  data,
}: {
  token: string;
  data: IResetPasswordPayload;
}) {
  const URL = `${endpoints.auth.passwords}`;
  const res = await axiosInstance.put(URL, data, { params: { token } });
  return res;
}
