// ----------------------------------------------------------------------

// import { isValid, parse } from 'date-fns';

export function emptyRows(page: number, rowsPerPage: number, arrayLength: number) {
  return page ? Math.max(0, (1 + page) * rowsPerPage - arrayLength) : 0;
}

function isNumeric(str: string) {
  if (typeof str !== 'string') return false;
  // @ts-ignore
  return !Number.isNaN(str) && !Number.isNaN(parseFloat(str));
}

// Function to parse a date string to a Date object
// function parseDate(dateStr: string): Date | null {
//   const parsedDate = parse(dateStr, 'yyyy-MM-dd', new Date());
//   return isValid(parsedDate) ? parsedDate : null;
// }

function convertStrToNoIfNo(str: number | string) {
  if (isNumeric(str as string)) {
    return parseFloat(str as string);
  }
  return str;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T, isDate: boolean = false) {
  if (isDate) {
    const dateA = a[orderBy] ? new Date(a[orderBy]).getTime() : null;
    const dateB = b[orderBy] ? new Date(b[orderBy]).getTime() : null;

    if (dateA === null && dateB === null) {
      return 0; // Both dates are null, consider them equal
    }
    if (dateA === null) {
      return 1; // dateA is null, so it should come after dateB
    }
    if (dateB === null) {
      return -1; // dateB is null, so it should come after dateA
    }
    // Compare dates directly
    if (dateA > dateB) {
      return -1; // dateA is later, so it should come before dateB (descending order)
    }
    if (dateA < dateB) {
      return 1; // dateA is earlier, so it should come after dateB (descending order)
    }
    return 0; // Dates are equal
  }

  // this normal sorting section
  const convertedValOfA = convertStrToNoIfNo(a[orderBy] as string | number);
  const convertedValOfB = convertStrToNoIfNo(b[orderBy] as string | number);

  if (convertedValOfA === null) {
    return 1;
  }
  if (convertedValOfB === null) {
    return -1;
  }
  if (convertedValOfB < convertedValOfA) {
    return -1;
  }
  if (convertedValOfB > convertedValOfA) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: 'asc' | 'desc',
  orderBy: Key,
  isDate: boolean = false,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, isDate)
    : (a, b) => -descendingComparator(a, b, orderBy, isDate);
}
