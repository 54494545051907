// sections
import OverviewDashboardView from 'src/sections/admin/overview/overview-dashboard-view';
import Head from 'src/components/head';
// hooks
import { useLocales } from 'src/locales';

export default function OverviewDashboardPage() {
  const { t } = useLocales();
  return (
    <>
      <Head title={`${t('overview')}`} />
      <OverviewDashboardView />
    </>
  );
}
