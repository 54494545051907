import Head from 'src/components/head';
import { useLocales } from 'src/locales';
// sections
import { View403 } from 'src/sections/error';

// ----------------------------------------------------------------------

export default function Page403() {
  const { t } = useLocales();
  return (
    <>
      <Head title={t('403_forbidden')} />

      <View403 />
    </>
  );
}
